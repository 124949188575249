import React from 'react';

type Props = {
    sectionRef?: React.MutableRefObject<HTMLElement[]>;
};

const Header = ({ sectionRef }: Props) => {
    const handleClick = (idx: number) => {
        sectionRef &&
            sectionRef.current[idx].scrollIntoView({
                block: 'start',
                behavior: 'smooth',
                inline: 'start',
            });
    };

    return (
        <header>
            <nav className="nav transparent">
                <div className="nav-links">
                    <div
                        className="nav-link"
                        id="nav-about"
                        tabIndex={0}
                        onClick={() => {
                            handleClick(1);
                        }}
                    >
                        ABOUT
                    </div>
                    <div
                        className="nav-link"
                        id="nav-projects"
                        tabIndex={0}
                        onClick={() => {
                            handleClick(2);
                        }}
                    >
                        PROJECTS
                    </div>
                    <div
                        className="nav-link"
                        id="nav-contact"
                        tabIndex={0}
                        onClick={() => {
                            handleClick(3);
                        }}
                    >
                        CONTACT
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header;
