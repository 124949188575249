import React from 'react';

type Props = {
    openResume: boolean;
    setOpenResume: React.Dispatch<React.SetStateAction<boolean>>;
};
const Resume = ({ openResume, setOpenResume }: Props) => {
    const handleClose = () => {
        setOpenResume(false);
    };

    return (
        <>
            {openResume && (
                <div className="modal">
                    <div className="modal-close" onClick={handleClose} />
                    <iframe src="/Jiayi_Ren_Resume_Full_Stack.pdf">
                        Unable to open the PDF file.
                    </iframe>
                </div>
            )}
        </>
    );
};

export default Resume;
