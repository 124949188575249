import React from 'react';

type Props = {
    sectionRef?: React.MutableRefObject<HTMLElement[]>;
    email: string;
    github: string;
    linkedIn: string;
    setOpenResume: React.Dispatch<React.SetStateAction<boolean>>;
};

const Footer = ({
    sectionRef,
    email,
    github,
    linkedIn,
    setOpenResume,
}: Props) => {
    const handleClick = (idx: number) => {
        sectionRef &&
            sectionRef.current[idx].scrollIntoView({
                block: 'start',
                behavior: 'smooth',
                inline: 'start',
            });
    };

    const handleOpen = () => {
        setOpenResume(true);
    };
    return (
        <footer className="footer">
            <div className="home-button-container">
                <div
                    className="home-button"
                    onClick={() => {
                        handleClick(0);
                    }}
                ></div>
            </div>
            <div className="copyright-links-container">
                <div className="links-container">
                    <a
                        href={`mailto:${email}`}
                        className="footer-icon email"
                    ></a>
                    <a href={github} className="footer-icon github"></a>
                    <a href={linkedIn} className="footer-icon linkedin"></a>
                    <div
                        className="footer-icon resume"
                        tabIndex={0}
                        onClick={handleOpen}
                    />
                </div>
                <div>©{new Date().getFullYear()} Jiayi Ren</div>
            </div>
        </footer>
    );
};

export default Footer;
