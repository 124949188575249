/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { ReactElement, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Header from './header';
import Footer from './footer';
import Resume from './resume';

type LayoutProps = {
    sectionRef?: React.MutableRefObject<HTMLElement[]>;
    children: (string | ReactElement)[];
};

const Layout = ({ sectionRef, children }: LayoutProps) => {
    const data = useStaticQuery(graphql`
        query MyQuery {
            dataJson {
                contact {
                    github
                    linkedIn
                    email
                }
            }
        }
    `);

    const contact = data.dataJson.contact;

    const [openResume, setOpenResume] = useState<boolean>(false);

    return (
        <>
            <Header sectionRef={sectionRef} />
            <main className="main-container">
                <Resume openResume={openResume} setOpenResume={setOpenResume} />
                {children}
            </main>
            <Footer
                sectionRef={sectionRef}
                email={contact.email}
                github={contact.github}
                linkedIn={contact.linkedIn}
                setOpenResume={setOpenResume}
            />
        </>
    );
};

export default Layout;
